import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import styles from './StickyV2.scss';

function getTopElement(offsetX: number): HTMLElement | null {
  try {
    const topElements = document.elementsFromPoint(offsetX, 0);
    return topElements.find((element) => !element.className.includes('modal-overlay')) as HTMLElement;
  } catch (e) {
    return null;
  }
}

function isPositionFixedOrRelative(element?: Element | null): boolean {
  if (!element) {
    return false;
  }

  try {
    const { position } = window.getComputedStyle(element);
    return position === 'fixed';
  } catch (e) {
    return false;
  }
}

function isDomElementVisible(element: HTMLElement | null): boolean {
  if (!element) {
    return false;
  }

  try {
    const { display, visibility, opacity } = window.getComputedStyle(element);
    return display !== 'none' && visibility !== 'hidden' && Number(opacity) === 1;
  } catch (e) {}

  return false;
}

function calculateElementOffset(element: HTMLElement | null) {
  if (
    element &&
    isDomElementVisible(element) &&
    !isElementTranslatedVertically(element) &&
    (isPositionFixedOrRelative(element) || isPositionFixedOrRelative(element?.parentElement))
  ) {
    return element.offsetHeight + element.offsetTop;
  } else {
    return 0;
  }
}

function isElementTranslatedVertically(element: HTMLElement | null) {
  if (!element) {
    return false;
  }

  try {
    const { transform } = window.getComputedStyle(element);
    const match = transform.match(
      /matrix\((-?\d*\.?\d*),\s(-?\d*\.?\d*),\s(-?\d*\.?\d*),\s(-?\d*\.?\d*),\s(-?\d*\.?\d*),\s(-?\d*\.?\d*)\)/,
    );
    return (
      transform !== 'none' &&
      (!match || (Number(match[match.length - 1]) > -100 && Number(match[match.length - 1]) < -5))
    );
  } catch (e) {
    return 0;
  }
}

function getFreemiumBarHeight() {
  try {
    return document.getElementById('WIX_ADS')?.offsetHeight || 0;
  } catch (e) {
    return 0;
  }
}

export function calculateTopValue(offsetX: number) {
  try {
    const topHeader = document.querySelector('#SITE_HEADER') as HTMLHeadElement;
    const topElement = topHeader.textContent ? topHeader : getTopElement(offsetX);
    const topElementOffset = calculateElementOffset(topElement);
    return topElementOffset || getFreemiumBarHeight();
  } catch (e) {}

  return 0;
}

export interface StickyProps {
  className?: string;
  offset?: number;
  onChange?: (isSticky: boolean) => void;
}

const Sticky: React.FC<StickyProps> = ({ className, offset = 0, children, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState(0);
  const [isAtTopOfViewPort, setAtTopOfViewPort] = useState(false);

  useEffect(() => {
    setImmediate(() => {
      setTop(calculateTopValue(ref.current?.offsetLeft || 0));
    });

    const handleObservedMutation = () => {
      setTimeout(() => {
        setTop(calculateTopValue(ref.current?.offsetLeft || 0));

        const rectTop = ref.current?.getBoundingClientRect().top;

        if (onChange && rectTop !== undefined) {
          const newAtTopOfViewPort = rectTop - top <= 0;

          if (newAtTopOfViewPort !== isAtTopOfViewPort) {
            setAtTopOfViewPort(newAtTopOfViewPort);
            onChange(newAtTopOfViewPort);
          }
        }
      }, 50);
    };

    if (typeof window !== 'undefined') {
      const throttled = _.throttle(handleObservedMutation, 50);
      const observer = new MutationObserver(throttled);
      const header = document.getElementById('SITE_HEADER') as Node;
      observer.observe(header, { attributes: true });
      header.addEventListener('transitionend', throttled);
      return () => {
        observer.disconnect();
        header.removeEventListener('transitionend', throttled);
      };
    }
  }, [ref, onChange, top, isAtTopOfViewPort]);

  return (
    <div ref={ref} className={`${className} ${styles.wrapper}`} style={{ top: top + offset }}>
      {children}
    </div>
  );
};

Sticky.displayName = 'Sticky';

export default Sticky;
