import React from 'react';
import NavigationHeader from '../NavigationHeader';
import Sticky from '../Sticky';
import StickyV2 from '../Sticky/StickyV2';
import styles from './StickyNavigationHeader.scss';
import { useExperiments } from 'yoshi-flow-editor-runtime';

export interface StickyNavigationHeaderProps {}

const StickyNavigationHeader: React.FC<StickyNavigationHeaderProps> = () => {
  const [showButtonPlaceholder, toggleShowButtonPlaceholder] = React.useState(false);
  const { experiments } = useExperiments();
  const isStickyNavigationV2Enabled = experiments.enabled('specs.restaurants.sticky-navigation-v2');
  const StickyComponent = isStickyNavigationV2Enabled ? StickyV2 : Sticky;

  return (
    <StickyComponent className={styles.wrapper} onChange={(isSticky) => toggleShowButtonPlaceholder(isSticky)}>
      <NavigationHeader showButtonPlaceholder={showButtonPlaceholder} />
    </StickyComponent>
  );
};

StickyNavigationHeader.displayName = 'StickyNavigationHeader';

export default StickyNavigationHeader;
