import React from 'react';
import ModalLayout from '../ModalLayout';
import VariationView from '../VariationView';
import DishModalFooter, { DishModalFooterPropagatedProps } from './DishModalFooter';
import Text from '../../core-components/Text';
import LabelsView from '../LabelsView';
import styles from './DishModal.scss';
import DishModalSpecialRequest from '../DishModalSpecialRequest';
import { Moment } from 'moment-timezone';
import Quantity from '../Quantity';
import dataHooks from '../../data-hooks';
import { scroller } from 'react-scroll';
import {
  DispatchType,
  DisplayableOrderItem,
  extractImageUrl,
  getDisplayablePrice,
  ItemsHash,
  Menu,
  OrderItem,
  UnavailabilityReason,
  VariationPath,
  getDisplayableOrderItem,
  toggleChoice,
  calcOrderItemGrandTotal,
} from '@wix/restaurants-client-logic';
import { EMPTY_ORDER_ITEM } from '../../../../core/constants';
import { SetPendingOrderItemPayload } from '../../../../state/cart/cart.actions.types';
import { useBi } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import { Logger } from '@wix/bi-logger-olo-client';
import {
  _hasInvalidVariations,
  _initializeErrorVisibilityObject,
  findSectionById,
  getInvalidVariations,
} from './dishModalUtils';
import { limitationToString } from '../VariationLimitationView/variationLimitationUtils';
import { ThumbnailImage, LoadingBehaviorOptions } from 'wix-ui-tpa/ThumbnailImage';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

export interface DishModalProps extends DishModalFooterPropagatedProps {
  onRequestClose: Function;
  setPendingOrderItem: (payload: SetPendingOrderItemPayload) => void;
  addPendingOrderItemToCart: Function;
  itemsHash: ItemsHash;
  pendingOrderItem: OrderItem | undefined;
  platform: string;
  showLabels: boolean;
  menu: Menu;
  locale: string;
  currency: string;
  dispatchType: DispatchType;
  dispatchTime: Moment;
  isMobile?: boolean;
  history: History<{ originSectionId?: string }>;
  t: TranslationFunction;
}

interface DishModalState {
  variationsWithVisibleErrors: Record<string, boolean>;
  pendingOrderItem: OrderItem;
  displayableOrderItem: DisplayableOrderItem;
}

export const getUnavailableText = (reason: string, t: TranslationFunction) => {
  switch (reason) {
    case UnavailabilityReason.SoldOut:
      return t('online_ordering_menuitem_soldout_label');
    case UnavailabilityReason.Unavailable:
      return t('online_ordering_menuitem_unavailable_label');
    default:
      return '';
  }
};

const withBiAndRouter = (Component: any) => (props: DishModalProps) => {
  const biLogger: Logger = useBi();
  const history: History = useHistory();
  return <Component biLogger={biLogger} {...props} history={history} />;
};

class DishModal extends React.Component<DishModalProps & { biLogger: Logger }, DishModalState> {
  displayName = 'DishModal';
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  tmp: number;

  constructor(props: DishModalProps & { biLogger: Logger }) {
    super(props);

    const pendingOrderItem: OrderItem = props.pendingOrderItem || EMPTY_ORDER_ITEM;

    this.state = {
      variationsWithVisibleErrors: {},
      pendingOrderItem,
      displayableOrderItem: this.getDisplayableOrderItem(pendingOrderItem),
    };

    this.scrollContainerRef = React.createRef();

    this.tmp = Math.random();
  }

  getDisplayableOrderItem(orderItem: OrderItem) {
    const { menu, itemsHash, locale, dispatchTime, platform, currency, dispatchType } = this.props;

    const sectionHint = findSectionById(menu, this.props.history.location?.state?.originSectionId);

    return getDisplayableOrderItem({
      menu,
      orderItem,
      itemsHash,
      locale,
      dispatchTime,
      platform,
      currency,
      dispatchType,
      sectionHint,
    });
  }

  _scrollToFirstError = (variationsWithVisibleErrors: Record<string, boolean>) => {
    const options = {
      container: this.scrollContainerRef.current,
      smooth: 'easeInOutCubic',
      duration: 200,
      offset: -75, // this is needed because the modal parallax image causes the scroll to not be completely accurate
    };

    const firstVariationWithErrors = Object.keys(variationsWithVisibleErrors)[0];
    if (firstVariationWithErrors) {
      scroller.scrollTo(firstVariationWithErrors, options);
    }
  };

  _handleSubmitButtonClick = () => {
    const { onRequestClose, setPendingOrderItem, addPendingOrderItemToCart, t } = this.props;
    const { pendingOrderItem, displayableOrderItem } = this.state;

    const hasInvalidVariations = _hasInvalidVariations(displayableOrderItem);

    if (hasInvalidVariations) {
      const variationsWithVisibleErrors = _initializeErrorVisibilityObject(displayableOrderItem.variations);
      const invalidVariations = getInvalidVariations(displayableOrderItem).map((variation) =>
        limitationToString(t, variation.limitation, variation.min, variation.max),
      );
      this.props.biLogger.addToCartValidationError({
        dishId: pendingOrderItem.itemId,
        errorsCount: invalidVariations.length,
        errorReason: invalidVariations.join('&'),
      });
      this.setState({ variationsWithVisibleErrors }, () => {
        this._scrollToFirstError(variationsWithVisibleErrors);
      });
    } else {
      onRequestClose();
      setPendingOrderItem({ orderItem: pendingOrderItem });
      addPendingOrderItemToCart();
    }
  };

  _handleVariationChange = (variationPath: VariationPath, choiceId: string, clientSideId: string) => {
    const variationsWithVisibleErrors = {
      ...this.state.variationsWithVisibleErrors,
      [clientSideId]: false,
    };

    const pendingOrderItem = toggleChoice({
      orderItem: this.state.pendingOrderItem,
      itemsHash: this.props.itemsHash,
      variationPath,
      choiceId,
    });

    this.setState({
      variationsWithVisibleErrors,
      pendingOrderItem,
      displayableOrderItem: this.getDisplayableOrderItem(pendingOrderItem),
    });
  };

  _handleQuantityChange = (quantity: number) => {
    const currentQuantity = this.state.displayableOrderItem.quantity;
    const action = quantity > currentQuantity ? 'increase' : 'decrease';
    this.props.biLogger.dishModalDishQuantitiesUpdate({
      action,
      dishId: this.state.displayableOrderItem.id,
      quantity,
    });

    const pendingOrderItem: OrderItem = {
      ...this.state.pendingOrderItem,
      count: quantity,
    };

    this.setState({
      pendingOrderItem,
      displayableOrderItem: this.getDisplayableOrderItem(pendingOrderItem),
    });
  };

  _handleCommentChange = (comment: string) => {
    const pendingOrderItem: OrderItem = {
      ...this.state.pendingOrderItem,
      comment,
    };

    this.setState({
      pendingOrderItem,
      displayableOrderItem: this.getDisplayableOrderItem(pendingOrderItem),
    });
  };

  renderAvailability = (text: string) => (
    <div className={styles.subTitle}>
      <Text data-hook={dataHooks.dishModalUnavailableDishText} typography="p2-l-60">
        {text}
      </Text>
    </div>
  );

  getPriceDifference = () => {
    const { locale, currency, pendingOrderItem: originalOrderItem } = this.props;
    const { pendingOrderItem } = this.state;

    return getDisplayablePrice(
      calcOrderItemGrandTotal(pendingOrderItem) - calcOrderItemGrandTotal(originalOrderItem || EMPTY_ORDER_ITEM),
      locale,
      currency,
    );
  };

  render() {
    const { itemsHash, onRequestClose, showLabels, biLogger, isMobile, t } = this.props;
    const { pendingOrderItem, displayableOrderItem } = this.state;
    const { variationsWithVisibleErrors } = this.state;
    const imageUrl = pendingOrderItem && extractImageUrl(itemsHash[pendingOrderItem.itemId]);
    const footer = (
      <DishModalFooter
        isEditingItemFromTheCart={this.props.isEditingItemFromTheCart}
        priceDifference={this.getPriceDifference()}
        onSubmitButtonClick={this._handleSubmitButtonClick}
        displayableOrderItem={displayableOrderItem}
        onQuantityChange={this._handleQuantityChange}
      />
    );

    // Deliberately using an empty string as alt text.
    // In this particular case adding the dish title/description will not have any effect,
    // since this data is shown directly under the image, and we do not want screen reader to read it twice.
    const alt = '';

    return (
      <ModalLayout
        onCloseClick={() => {
          biLogger.dishModalDiscard({ dishId: pendingOrderItem.itemId });
          onRequestClose();
        }}
        data-hook={dataHooks.dishModal}
        parallax={
          imageUrl && (
            <ThumbnailImage
              src={imageUrl}
              width={isMobile ? 400 : 560}
              height={isMobile ? 220 : 420}
              alt={alt}
              loadingBehavior={LoadingBehaviorOptions.blur}
              fluid
            />
          )
        }
        header={displayableOrderItem.title}
        footer={footer}
        scrollContainerRef={this.scrollContainerRef}
      >
        {showLabels && <LabelsView labels={displayableOrderItem.labels} />}
        {displayableOrderItem.errors[0]?.type === 'order_delivery_time' &&
          this.renderAvailability(getUnavailableText(displayableOrderItem.errors[0].reason, t))}
        {displayableOrderItem.subTitle && (
          <Text typography="p2-m" className={styles.subTitle} id="modal-description">
            {displayableOrderItem.subTitle}
          </Text>
        )}
        <div className={styles.spacer20} />
        {displayableOrderItem.variations.map((displayableVariation) => {
          return (
            <VariationView
              key={displayableVariation.clientSideId}
              displayableVariation={displayableVariation}
              onChange={this._handleVariationChange}
              variationsWithVisibleErrors={variationsWithVisibleErrors}
              shouldDisplayError={variationsWithVisibleErrors[displayableVariation.clientSideId]}
              currency={this.props.currency}
            />
          );
        })}
        <DishModalSpecialRequest
          value={pendingOrderItem?.comment}
          onChange={this._handleCommentChange}
          disabled={displayableOrderItem.submitDisabled}
        />
        <div className={styles.quantity}>
          <Text typography="p2-m" className={styles.subTitle}>
            Quantity
          </Text>

          <Quantity
            data-hook={dataHooks.dishModalQuantity}
            value={displayableOrderItem.quantity}
            onChange={(val) => {
              const n = Number(val);
              n >= 1 && n <= 999 && this._handleQuantityChange(n);
            }}
            aria-label="Quantity Selector"
            inputAriaLabel="Quantity"
            incrementAriaLabel="Increase"
            decrementAriaLabel="Decrease"
            min={1}
            step={1}
            disabled={displayableOrderItem.submitDisabled}
            halfWidth
          />
        </div>
      </ModalLayout>
    );
  }
}

export default withBiAndRouter(DishModal);
