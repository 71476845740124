import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/min/locales.min';
import { translate, TranslationFunction } from 'yoshi-flow-editor-runtime';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Spinner } from 'wix-ui-tpa/Spinner';
import usePhoneUtils from '../../../../core/hooks/usePhoneUtils';
import usePaymentIcon from './usePaymentIcon';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import styles from './ThankYou.scss';
import ThankYouRestaurantDetails from './ThankYouRestaurantDetails';
import { extractLocalizedString, getDisplayablePrice, Order, Payment, Restaurant } from '@wix/restaurants-client-logic';
import Button from '../Button';
import Scroller from '../Scroller';

export interface ThankYouProps {
  order: Order;
  locale: string;
  currency: string;
  timezone: string;
  restaurant: Restaurant;
  initCheckout: Function;
  initAddressForm: Function;
  t: TranslationFunction;
}

function getPaymentMethodTitle(payment: Payment) {
  if (payment.type === 'cashier') {
    return payment.creditCard ? `●●●● ${payment.creditCard.lastDigits}` : payment.paymentMethodTitle;
  }

  return 'Offline';
}

const PaymentMethod: React.FC<{ payment: Payment; locale: string }> = ({ payment, locale }) => {
  const iconSrc = usePaymentIcon(
    locale,
    payment.type === 'cashier',
    payment.type === 'cashier' ? payment.paymentMethod : undefined,
  );

  return (
    <span>
      {payment.type === 'cash' ? (
        <img
          data-hook={dataHooks.thankYouPagePaymentIcon}
          src="http://images-wixmp-6613fa290e8c1ac70a0297b6.wixmp.com/payment-methods/onboarding/png/offline.png/v1/fit/w_42,h_25/offline.png"
          alt="Payment Icon"
        />
      ) : (
        iconSrc && <img data-hook={dataHooks.thankYouPagePaymentIcon} src={iconSrc} alt="Payment Icon" />
      )}
      <Text typography="p2-m" data-hook={dataHooks.thankYouPagePaymentMethod} className={styles.paymentMethodTitle}>
        {getPaymentMethodTitle(payment)}
      </Text>
    </span>
  );
};

const ThankYou: React.FC<ThankYouProps> = ({
  order,
  locale,
  currency,
  timezone,
  restaurant,
  initAddressForm,
  initCheckout,
  t,
}) => {
  const history = useHistory();
  const PhoneUtils = usePhoneUtils();
  const resetUserOrderSession = () => {
    initAddressForm();
    initCheckout();
  };

  React.useEffect(() => {
    if (!order) {
      history.replace('/');
    }
  }, [order, history]);

  if (!order) {
    return <div />;
  }

  return (
    <Scroller name="thank-you" condition>
      <div className={styles.wrapper} data-hook={dataHooks.thankYouPage}>
        {PhoneUtils ? (
          <>
            <Text className={styles.header} typography="header-m" data-hook={dataHooks.thankYouPageTitle}>
              {t('thank_you_page_pickup.title')}
            </Text>
            <Text className={styles.subheader} typography="p2-l" data-hook={dataHooks.thankYouPageSubtitle}>
              {t('thank_you_page_pickup.subtitle', { name: `${order.contact.firstName} ${order.contact.lastName}` })}
            </Text>
            <div className={styles.details}>
              <div className={styles.detailWrapper}>
                <Text typography="p2-m">{t('thank_you_page_order_no')}</Text>
                <Text typography="p2-m" data-hook={dataHooks.thankYouPageOrderId}>
                  {order.id}
                </Text>
              </div>
              {order.delivery.time && (
                <div className={styles.detailWrapper}>
                  <Text typography="p2-m">{t('thank_you_page_delivery_time')}</Text>
                  <Text typography="p2-m" data-hook={dataHooks.thankYouPageDeliveryTime}>
                    {moment(order.delivery.time).tz(timezone).locale(locale).calendar()}
                  </Text>
                </div>
              )}
              {order.delivery.type === 'delivery' && (
                <div className={styles.detailWrapper}>
                  <Text typography="p2-m">{t('thank_you_page_delivery_to')}</Text>
                  <Text typography="p2-m" data-hook={dataHooks.thankYouPageDeliveryAddress}>
                    {order.delivery.address.formatted}
                  </Text>
                </div>
              )}
              {order.delivery.type === 'takeout' && order.delivery.isCurbside && (
                <div className={styles.detailWrapper}>
                  <Text typography="p2-m">{t('thank_you_page_deliverymethod_CurbsidePickup_Label')}</Text>
                  <Text typography="p2-m" data-hook={dataHooks.thankYouPageCurbside}>
                    {order.delivery.curbsideAdditionalInformation}
                  </Text>
                </div>
              )}
              <div className={styles.detailWrapper}>
                <Text typography="p2-m">{t('thank_you_page_payment_method')}</Text>
                <PaymentMethod locale={locale} payment={order.payments[0]} />
              </div>
              <div className={styles.detailWrapper}>
                <Text typography="p2-m">{t('thank_you_page_total')}</Text>
                <Text typography="p2-m" data-hook={dataHooks.thankYouPagePrice}>
                  {getDisplayablePrice(order.price, locale, currency)}
                </Text>
              </div>
            </div>
            {order.delivery.type === 'takeout' && (
              <ThankYouRestaurantDetails
                restaurantName={extractLocalizedString(restaurant.title, restaurant.locale)}
                address={restaurant.address}
              />
            )}
            <Text className={styles.footer} typography="p2-s-secondary">
              {order.created && (
                <span data-hook={dataHooks.thankYouPageSubmittedAt}>
                  {t('thank_you_page_order_submitted', { orderTime: moment(order.created).tz(timezone).format('LT') })}
                  .&nbsp;
                </span>
              )}
              <span data-hook={dataHooks.thankYouPagePhoneNumber}>
                {t('thank_you_page_questions', {
                  phoneNumber: PhoneUtils.displayPhone(restaurant.contact.phone, restaurant.address.countryCode),
                })}
              </span>
            </Text>
            <Link to="/">
              <Button
                data-hook={dataHooks.thankYouPageHomeButton}
                className={styles.backButton}
                priority={PRIORITY.primary}
                upgrade
                onClick={resetUserOrderSession}
              >
                {t('thank_you_page_cta')}
              </Button>
            </Link>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Scroller>
  );
};

export default translate()(ThankYou);
