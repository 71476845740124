import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import dataHooks from '../../data-hooks';
import React from 'react';
import Text from '../../core-components/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from '../TextButton';
import styles from './MemberLoginCTA.scss';

interface MemberLoginCTAProps {
  onLoginClick: () => void;
  onLogoutClick: () => void;
  userEmail?: string;
  isUserLoggedIn: boolean;
  isMobile?: boolean;
}

export const MemberLoginCTA = (props: MemberLoginCTAProps) => {
  const { onLoginClick, onLogoutClick, userEmail, isUserLoggedIn, isMobile } = props;
  const Logout = () => (
    <TextButton
      colorless
      priority={TEXT_BUTTON_PRIORITY.link}
      onClick={onLogoutClick}
      data-hook={dataHooks.memberCTALogout}
    >
      <Text typography="p2-s-colorless"> Logout</Text>
    </TextButton>
  );
  const content = !isUserLoggedIn ? (
    <div>
      <Text typography={'p2-s-colorless'}>Already have an account? </Text>
      <span>
        <TextButton
          colorless
          priority={TEXT_BUTTON_PRIORITY.link}
          onClick={onLoginClick}
          data-hook={dataHooks.memberCTALogin}
        >
          <Text typography="p2-s-colorless">Login</Text>
        </TextButton>{' '}
      </span>
      <Text typography={'p2-s-colorless'}>for a faster checkout.</Text>
    </div>
  ) : (
    <>
      <Text typography={'p2-s-colorless'}>Logged in as {userEmail}</Text>
      {isMobile && (
        <>
          <span>{'  '}</span>
          <Logout />
        </>
      )}
    </>
  );

  return (
    <div className={styles.wrapper}>
      <SectionNotification data-hook={dataHooks.memberCTASection}>
        <SectionNotification.Text>{content}</SectionNotification.Text>
        {isUserLoggedIn && !isMobile && <Logout />}
      </SectionNotification>
    </div>
  );
};
