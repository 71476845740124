import {
  DisplayableOrderItem,
  getDisplayablePrice,
  validateAddress,
  ValidateAddressReason,
} from '@wix/restaurants-client-logic';
import { TranslationFunction } from 'i18next';
import { Address } from '@wix/restaurants-client-logic/dist/types/types/Address';
import { Restaurant } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';

export function getErrorKey(orderItem: DisplayableOrderItem) {
  if (orderItem.isDishDeleted) {
    return 'cart_issues_error_hidden';
  }

  const error = orderItem.errors[0];

  switch (error?.type) {
    case 'order_delivery_type':
      return 'cart_issues_error_delivery_method_unavailable';
    case 'order_delivery_time':
      return error.reason === 'soldout' ? 'cart_issues_error_out_of_stock' : 'cart_issues_error_delivery_time_change';
    case 'order_platform':
      return 'cart_issues_error_unavailable_platform';
    default:
      return undefined;
  }
}

export function getValidateAddressReasonText(
  currency: string,
  locale: string,
  t: TranslationFunction,
  addressInputValue: string,
  reason?: ValidateAddressReason,
) {
  if (reason?.type === 'out-of-bounds') {
    return t('checkout_main_delivery_address_errormessage');
  } else if (reason?.type === 'unavailable') {
    return t('checkout_main_address_unavailable_errormessage');
  } else if (reason?.type === 'minimum-price') {
    return t('checkout_main_delivery_minimumprice_errormessage', {
      price: getDisplayablePrice(reason.minPrice, locale, currency),
    });
  } else if (reason?.type === 'pinpoint-error') {
    return t('checkout_main_buildingnumber_errormessage');
  } else if (reason?.type === 'invalid-address') {
    return addressInputValue === '' ? t('checkout_main_address_mandatory') : t('checkout_main_address_invalid');
  }
}

interface GetDisplayableAddressErrorArgs {
  address: Address;
  restaurant: Restaurant;
  totalOrderPrice?: number;
  dispatchTime?: number;
  t: TranslationFunction;
}

export function getDisplayableAddressError({
  address,
  restaurant,
  dispatchTime,
  totalOrderPrice,
  t,
}: GetDisplayableAddressErrorArgs) {
  const validateAddressReason = validateAddress({
    address,
    restaurant,
    dispatchTime,
    totalOrderPrice,
  });

  return getValidateAddressReasonText(
    restaurant.currency,
    restaurant.locale,
    t,
    address.formatted,
    validateAddressReason,
  );
}
