import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Modal from '../Modal';
import { Modals } from '../../../../core/constants';
import DishModal from '../DishModal';
import { CloseModalPayload, OpenModalPayload } from '../../../../state/session/session.actions.types';
import OrderFailureModal from '../OrderFailureModal';
import DispatchSettings from '../DispatchSettings';
import CartModal from '../CartModal';
import CartModalCheckoutFlow from '../CartModal/CartModalCheckoutFlow';
import TermsAndConditionsModal from '../TermsAndConditionsModal';
import PrivacyPolicyModal from '../PrivacyPolicyModal';
import SmsModal from '../SmsModal';
import UpgradeToPremiumModal from '../UpgradeToPremiumModal';
import ConnectPaymentModal from '../ConnectPaymentModal';
import AddressSelectionModal from '../AddressSelectionModal';
import LogoutModal from '../LogoutModal';
import ChangeLocationModal from '../ChangeLocationModal';

const ModalComponents = {
  [Modals.DISH_MODAL]: DishModal,
  [Modals.ORDER_FAILURE_MODAL]: OrderFailureModal,
  [Modals.DISPATCH_SETTINGS_MODAL]: DispatchSettings,
  [Modals.CART_MODAL]: CartModal,
  [Modals.CART_MODAL_CHECKOUT]: CartModalCheckoutFlow,
  [Modals.TERMS_AND_CONDITIONS]: TermsAndConditionsModal,
  [Modals.PRIVACY_POLICY]: PrivacyPolicyModal,
  [Modals.SMS_MODAL]: SmsModal,
  [Modals.CONNECT_PAYMENT_MODAL]: ConnectPaymentModal,
  [Modals.UPGRADE_TO_PREMIUM_MODAL]: UpgradeToPremiumModal,
  [Modals.ADDRESS_SELECTION]: AddressSelectionModal,
  [Modals.LOGOUT_MODAL]: LogoutModal,
  [Modals.CHANGE_LOCATION_MODAL]: ChangeLocationModal,
};

type ModalMetadata = {
  shouldCloseOnEsc: boolean;
  shouldCloseOnOverlayClick: boolean;
  isSideContent: boolean;
};

const ModalMetadataByType: Partial<Record<keyof typeof Modals, ModalMetadata>> = {
  [Modals.SMS_MODAL]: {
    shouldCloseOnEsc: false,
    shouldCloseOnOverlayClick: false,
    isSideContent: false,
  },
  [Modals.CART_MODAL]: {
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
    isSideContent: true,
  },
  [Modals.CART_MODAL_CHECKOUT]: {
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
    isSideContent: true,
  },
  [Modals.ORDER_FAILURE_MODAL]: {
    shouldCloseOnEsc: false,
    shouldCloseOnOverlayClick: false,
    isSideContent: false,
  },
};

export interface ModalManagerProps {
  modals: OpenModalPayload[];
  closeModal: (payload: CloseModalPayload) => void;
}

class ModalManager extends React.Component<ModalManagerProps & RouteComponentProps> {
  static displayName = 'ModalManager';

  static defaultProps = {
    modals: [],
  };

  componentDidMount() {
    Modal.setAppElementSelectors('#modal-wrapper', '#main-page');
  }

  isSideContent(modal?: OpenModalPayload): boolean {
    return Boolean(modal && ModalMetadataByType[modal.modal]?.isSideContent);
  }

  shouldCloseOnEsc(modal?: OpenModalPayload): boolean | undefined {
    return modal && ModalMetadataByType[modal.modal]?.shouldCloseOnEsc;
  }

  shouldCloseOnOverlayClick(modal?: OpenModalPayload): boolean | undefined {
    return modal && ModalMetadataByType[modal.modal]?.shouldCloseOnOverlayClick;
  }

  shouldComponentUpdate(prevProps: ModalManagerProps) {
    return this.props.modals.length !== prevProps.modals.length;
  }

  renderModal = (dataHook: string, modal?: OpenModalPayload, CurrModal?: React.FC<{ onRequestClose: Function }>) => {
    const { closeModal, history } = this.props;

    return (
      <Modal
        data-hook={dataHook}
        isOpen={!!CurrModal}
        onRequestClose={() => {
          if (modal) {
            closeModal({ modal: modal.modal });

            if (modal.redirectOnClose) {
              history.push(modal.redirectOnClose);
            }
          }
        }}
        isSideContent={this.isSideContent(modal)}
        aria={{ labelledby: 'modalTitle', describedby: 'modal-description' }}
        shouldCloseOnEsc={this.shouldCloseOnEsc(modal)}
        shouldCloseOnOverlayClick={this.shouldCloseOnOverlayClick(modal)}
      >
        {CurrModal && (
          <CurrModal
            onRequestClose={() => {
              if (modal) {
                closeModal({ modal: modal.modal });

                if (modal.redirectOnClose) {
                  history.push(modal.redirectOnClose);
                }
              }
            }}
            {...(modal?.context ?? {})}
          />
        )}
      </Modal>
    );
  };

  render() {
    const { modals } = this.props;

    if (modals.length > 2) {
      throw new Error('Cannot have more than 2 modals open at a time.');
    }

    let FirstModal, SecondModal;

    if (modals.length > 0) {
      FirstModal = ModalComponents[modals[0].modal];
    }

    if (modals.length > 1) {
      SecondModal = ModalComponents[modals[1].modal];
    }

    return (
      <React.Fragment>
        {this.renderModal('first-modal', modals[0], FirstModal)}
        {this.renderModal('second-modal', modals[1], SecondModal)}
      </React.Fragment>
    );
  }
}

export default withRouter(ModalManager);
