import CheckoutFlow from './CheckoutFlow';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { businessNotificationSelectorByDispatchTime } from '../../../../state/selectors/businessNotificationSelector';
import { loginMember, logoutMember, setCheckoutStep } from '../../../../state/checkout/checkout.actions';
import { translate } from 'yoshi-flow-editor-runtime';
import { BusinessNotifications, Contact } from '@wix/restaurants-client-logic';
import { getOrderPolicy } from '../../../../core/logic/policyLogic';
import { closeModal, openModal } from '../../../../state/session/session.actions';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';

function mapStateToProps(state: AppState) {
  const businessNotification = businessNotificationSelectorByDispatchTime(state)?.notification;
  const { displayableOrderItems } = cartSummarySelector(state);
  const shouldGoBack =
    businessNotification === BusinessNotifications.OlOunavailable ||
    businessNotification === BusinessNotifications.RestaurantClosed ||
    (!state.checkout.orderResponse && displayableOrderItems.length === 0);

  return {
    orderPolicy: getOrderPolicy(state.session.restaurant),
    paymentMethod: state.checkout.pendingCashierPayment.paymentMethod,
    contact: state.checkout.contact,
    dispatchType: state.checkout.dispatch.type,
    isMobile: state.platformParams.isMobile,
    shouldGoBack,
    step: state.checkout.checkoutStep,
    isContactlessDeliveryChecked: state.checkout.isContactlessDeliveryChecked,
    initialContact: getInitialContact(state),
    isMemberContactLoading: state.checkout.isMemberContactLoading,
    isUserLoggedIn: state.session.isUserLoggedIn,
    hasMembersAreaIntegration: state.platformParams.settings.hasMembersAreaIntegration,
  };
}

function getInitialContact(state: AppState): Contact {
  const { contact, membersApiContact } = state.checkout;
  if (!state.platformParams.settings.hasMembersAreaIntegration) {
    return contact;
  }
  if (contact.firstName || contact.lastName || contact.phone) {
    if (membersApiContact.emails) {
      contact.email = membersApiContact.emails[0];
    }
    return contact;
  }
  const { firstName, lastName, emails = [], phones = [] } = membersApiContact;
  return {
    ...contact,
    firstName: firstName ? firstName : '',
    lastName: lastName ? lastName : '',
    email: emails[0] || '',
    phone: phones[0] || '',
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ setStep: setCheckoutStep, closeModal, loginMember, logoutMember, openModal }, dispatch);
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(CheckoutFlow));
